@import url("https://use.typekit.net/qif4fcq.css");
@import "~antd/dist/antd.less";
@tailwind base;
@tailwind components;
@tailwind utilities;

.ql-editor {
  min-height: 250px;
  background-color: white;
}
.anticon svg {
  display: block;
}
.ant-card-type-inner .ant-card-head-title {
  font-size: 15px;
  font-weight: 600;
  color: #9d7c38;
}
.login-page {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-image: url("./assets/images/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.signatureCanvas {
  border: 1px solid #ddd;
  width: 100%;
  height: 300px;
}

.bg404 {
  background-image: url("./assets/images/404.jpg");
}
.container {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  background-color: white !important;
}
.amenu {
  background-color: transparent;
  margin-top: 50px;
}
.amenu .ant-menu-item-selected {
  background-color: white !important;
  border-top: 1px solid #ddd !important;
  border-bottom: 1px solid #ddd !important;
}
.desktop-sider {
  margin-top: 64px;
  background-color: #fafafa;
}
.mobil-sider {
  position: absolute;
  z-index: 1;
  min-height: 100%;
  border-right: 1px solid #ddd;
  background-color: #f5f7f9;
}
.ant-menu-item-selected {
  font-weight: 700;
}
.amenu {
  background-color: transparent;
  margin-top: 10px;
}
.amenu .ant-menu-item-selected {
  background-color: white !important;
  border-top: 1px solid #ddd !important;
  border-bottom: 1px solid #ddd !important;
}
.ant-drawer-body {
  padding: 0;
}
.ant-menu-horizontal {
  border-bottom: none;
}
.ant-menu-overflow-item.ant-menu-item {
  padding: 0 15px !important;
  text-transform: uppercase;
  font-size: 15px;
  color: #003975;
}

.footer {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;

  background-color: #fafafa;
  padding: 15px 0 0 0;
}
@media print {
  @page {
    size: landscape;
    margin: 25px;
  }
}
.ant-divider-inner-text {
  color: #368ce9 !important;
  font-weight: 600;
}

@primary-color: #9d7c38;